<template>
	<div>
		<v-container fluid >
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="10" xl="10">
					<h2>特定商取引法に基づく表記</h2>
					<div v-html="flist.contents_parts1"></div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import fcms from "@/api/fcms.js";

	export default {
		data() {
			return {
				//fcms情報
				fcms: [],
				flist:[],

			}
		},
		methods :{
			// APIから情報を取得
			async setFcms(info) {
				this.fcms = info.data;

				console.log(this.fcms)

				// 取得エラー
				console.log(this.fcms.result);
				if(this.fcms.result != 'success') {
					this.result = 'error'
				}

				// アイキャッチの取得
				if(this.fcms[0].contents == 1 && this.fcms[0].contents_bloc == 6) {
					this.flist = this.fcms[0]
				}

			},
		},
		mounted() {
			fcms.fetchList(this.setFcms,1,6);
		}
	}
</script>
<style scoped>
	@import "~@/styles/style.css";

</style>
